import 'rollbar'

var vite_env = import.meta.env

var _rollbarConfig = {
  enabled: vite_env.PROD,
  accessToken: vite_env.VITE_ROLLBAR_CLIENT_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,

  // checkIgnore: function(isUncaught, args, payload) {
  //     // Code here to determine whether or not to send the payload
  //     // to the Rollbar API
  //     // return true to ignore the payload
  // },

  payload: {
    environment: vite_env.VITE_RAILS_ENV,
    client: {
      javascript: {
        code_version: vite_env.VITE_CODE_VERSION,
        // source_map_enabled: true
      }
    }
  }
}

window.Rollbar = new rollbar(_rollbarConfig)
